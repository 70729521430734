<template>
  <div class="w_collect_material">
    <div class="bg_box"></div>
    <div class="material_list">
      <div class="keyword_box">
        <Keyword @goSearch="goSearch"></Keyword>
      </div>
      <MaterialList :keyword="data.keyword" :is_collection="1"></MaterialList>
    </div>

  </div>
</template>

<script setup>
import { reactive } from 'vue';
import Keyword from '../../components/Common/Keyword.vue';
import MaterialList from '../../components/Material/Listcom.vue'

const data = reactive({
  keyword: ''
});

function goSearch(e) {
  data.keyword = e;
}

</script>

